export const getBackgroudColorByStatus = (status: string) => {
  switch (status) {
    case 'waiting':
      return '#FF9776';
    case 'calling':
      return '#81FF7633';
    case 'called':
      return '#FF977633';
    default:
      return 'gray';
  }
};

export const getColorByStatus = (status: string) => {
  switch (status) {
    case 'waiting':
      return '#ED3D31';
    case 'calling':
      return '#1CAD10';
    case 'called':
      return '#ED3D31';
    default:
      return 'gray';
  }
};

export const getLabelByStatus = (status: string) => {
  switch (status) {
    case 'waiting':
      return 'Aranmadı';
    case 'calling':
      return 'Tekrar Aranacak';
    case 'called':
      return 'Tamamlandı';
    default:
      return 'default';
  }
};

export const getActionLabelByStatus = (status: string) => {
  switch (status) {
    case 'waiting':
      return 'Görüşme Gerçekleştir';
    case 'calling':
      return 'Görüşmeyi Sonlandır';
    case 'called':
      return 'İşlem Sonlandırıldı';
    default:
      return '';
  }
};

export const nextActionHandler = (status: string) => {
  switch (status) {
    case 'waiting':
      return 'calling';
    case 'calling':
      return 'called';
  }
};

export const convertToFirstCharUppercase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase('tr');
};

export const parseCurrencyStringToNumber = (currencyString: string) => {
  // Türk Lirası (₺) simgesini ve binlik ayraç olarak kullanılan virgülü kaldırın
  const cleanedString = currencyString?.replace('₺', '')?.replace(/\./g, '');

  // Virgül ve nokta karakterlerinin yerini belirleyin
  const commaIndex = cleanedString.indexOf(',');
  const dotIndex = cleanedString.lastIndexOf('.');

  // Eğer hem virgül hem de nokta varsa ve virgül son karakterde ise, nokta karakterini kaldırın
  if (
    commaIndex !== -1 &&
    dotIndex !== -1 &&
    commaIndex === cleanedString.length - 1
  ) {
    const formattedString = cleanedString?.replace('.', '');
    return parseFloat(formattedString?.replace(',', '.'));
  } else {
    // Stringde sadece bir virgül varsa, bu virgülü ondalık nokta olarak kabul edin
    const formattedString = cleanedString?.replace(',', '.');
    return parseFloat(formattedString);
  }
};

export const isEmptySessionObj = (obj: {} | { [keys: string]: string }) => {
  if (Object.keys(obj).length > 0) {
    return false;
  }
  return true;
};

export const formatPhoneNumberForBackend = (phoneNumber: any) => {
  const formattedPhoneNumber = phoneNumber?.replace(
    /^(\+?90|0)?[\s-]?(\d{3})[\s-]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})$/,
    '90$2$3$4$5'
  );
  return formattedPhoneNumber;
};

export const formatPhoneNumber = (phoneNumber: any) => {
  if(phoneNumber){
    return phoneNumber.replace(
      /^(\+?90|0)?[\s-]?(\d{3})[\s-]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})$/,
      '0$2 $3 $4 $5'
    );
  }else
    return phoneNumber;
};

export function addMonths(date: Date, months: number) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

export function generatePreWhatsUpMessageLink(phoneNumber: number) {
  return `https://wa.me/${phoneNumber}`;
}

export const generateRandomHex = () => {
  let hexString = '';
  while (hexString.length < 11) {
    const randomInt = Math.floor(Math.random() * (1 << 24));
    const part = randomInt.toString(16);
    hexString += part;
  }
  return hexString.slice(0, 11);
};
